<!--
 * @Author: lbh
 * @Date: 2022-12-26 14:39:27
 * @LastEditors: lbh
 * @LastEditTime: 2023-07-13 16:36:52
 * @Description: file content
-->
<template>
  <div
    class="marketingToolGraphicText3-box px-marketingToolGraphicText3-box"
    :class="`edit_${Xindex}_box `"
    @click="clickItem('box')"
  >
    <h1
      class="title"
      v-html="$util.rex.getHtml(configs[`${nowL}title`] || configs.title)"
    ></h1>
    <h2
      class="sub-title"
      v-html="$util.rex.getHtml(configs[`${nowL}subTitle`] || configs.subTitle)"
    ></h2>

    <div class="a-box">
      <!-- <div
        v-for="(item,index) in configs.items"
        :key="index"
        class="item"
        :style="`transform: rotateZ(${index * 90}deg);`"
      >
        <div class="cell">
          <img
            :src="item.icon"
            class="c-icon"
          />
          <div
            v-text="item.title"
            class="c-title"
          ></div>
          <div
            class="c-content"
            v-html="$util.rex.getHtml(item.content)"
          ></div>
        </div>
      </div> -->
      <div class="line1">
        <div class="cell">
          <img
            :src="configs.items[0].icon"
            class="c-icon"
          />
          <div
            v-html="$util.rex.getHtml(configs.items[0][`${nowL}title`] || configs.items[0].title)"
            class="c-title"
          ></div>
          <div
            class="c-content"
            v-html="$util.rex.getHtml(configs.items[0][`${nowL}content`] || configs.items[0].content)"
          ></div>
        </div>
      </div>
      <div class="line2">
        <div
          class="cell"
          :style="`${configs.items[3] ? `` : `visibility: hidden;`}`"
        >
          <template v-if="configs.items[3]">
            <img
              :src="configs.items[3].icon"
              class="c-icon"
            />
            <div
              v-html="$util.rex.getHtml(configs.items[3][`${nowL}title`] || configs.items[3].title)"
              class="c-title"
            ></div>
            <div
              class="c-content"
              v-html="$util.rex.getHtml(configs.items[3][`${nowL}content`] || configs.items[3].content)"
            ></div>
          </template>
        </div>
        <div class="b-box">
          <div
            class="c-box"
            v-html="$util.rex.getHtml(configs[`${nowL}centerText`] || configs.centerText)"
          ></div>
        </div>
        <div
          class="cell"
          :style="`${configs.items[1] ? `` : `visibility: hidden;`}`"
        >
          <template v-if="configs.items[1]">
            <img
              :src="configs.items[1].icon"
              class="c-icon"
            />
            <div
              v-html="$util.rex.getHtml(configs.items[1][`${nowL}title`] || configs.items[1].title)"
              class="c-title"
            ></div>
            <div
              class="c-content"
              v-html="$util.rex.getHtml(configs.items[1][`${nowL}content`] || configs.items[1].content)"
            ></div>
          </template>
        </div>
      </div>
      <div class="line3">
        <div
          class="cell"
          v-if="configs.items[2]"
        >
          <img
            :src="configs.items[2].icon"
            class="c-icon"
          />
          <div
            v-html="$util.rex.getHtml(configs.items[2][`${nowL}title`] || configs.items[2].title)"
            class="c-title"
          ></div>
          <div
            class="c-content"
            v-html="$util.rex.getHtml(configs.items[2][`${nowL}content`] || configs.items[2].content)"
          ></div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import langMixin from '../../mixins/lang';
export default {
  mixins: [langMixin],
  props: {
    configs: {
      default () {
        return {
          title: "",
          subTitle: '',
          items: []
        };
      },
    },
    Xindex: {
      default () {
        return '';
      },
    },
  },
  data () {
    return {
      isEdit: false
    }
  },
  created () {
    this.isEdit = this.$store.getters.getIsEdit;
  },
  methods: {
    clickItem (e, x, t) {
      if (this.isEdit) {
        this.$emit('clickItem', e, x, t);
      }
    },
  }
}
</script>

<style lang="less" scoped>
.marketingToolGraphicText3-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 0 90px;
  .title {
    font-size: 30px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
  }
  .sub-title {
    font-size: 20px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    margin-top: 6px;
  }
  .a-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    .cell {
      padding: 24px 30px 32px;
      background: rgba(252, 241, 244, 1);
      border-radius: 6px;
      position: relative;
      width: 390px;
      .c-icon {
        position: absolute;
        top: -8px;
        left: 10px;
        width: 44px;
      }
      .c-title {
        font-size: 24px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        text-align: center;
      }
      .c-content {
        font-size: 18px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        text-align: center;
        margin-top: 15px;
      }
    }
    .line1 {
      margin-bottom: 35px;
      .cell {
        &::after {
          position: absolute;
          content: "";
          border-width: 15px;
          border-style: solid;
          border-color: rgba(252, 241, 244, 1) transparent transparent
            transparent;
          bottom: -30px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    .line2 {
      width: 100%;
      display: flex;
      margin-bottom: 35px;
      justify-content: center;
      // align-items: center;
      .cell {
        &:first-child {
          margin-right: 23px;
          &::after {
            position: absolute;
            content: "";
            border-width: 15px;
            border-style: solid;
            border-color: transparent transparent transparent
              rgba(252, 241, 244, 1);
            right: -30px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        &:last-child {
          margin-left: 23px;
          &::after {
            position: absolute;
            content: "";
            border-width: 15px;
            border-style: solid;
            border-color: transparent rgba(252, 241, 244, 1) transparent
              transparent;
            left: -30px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
    .line3 {
      margin-bottom: 35px;
      .cell {
        &::after {
          position: absolute;
          content: "";
          border-width: 15px;
          border-style: solid;
          border-color: transparent transparent rgba(252, 241, 244, 1)
            transparent;
          top: -30px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    .b-box {
      width: 182px;
      height: 182px;
      background-color: rgb(190, 28, 66);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      align-self: center;
      &::after {
        position: absolute;
        content: "";
        border-width: 20px;
        border-style: solid;
        border-color: rgb(190, 28, 66) rgb(190, 28, 66) transparent transparent;
        top: 12.5px;
        right: calc(50% - 15px);
        transform-origin: right top;
        transform: rotate(40deg);
      }
      &::before {
        position: absolute;
        content: "";
        border-width: 20px;
        border-style: solid;
        border-color: transparent transparent rgb(190, 28, 66) rgb(190, 28, 66);
        bottom: 12.5px;
        left: calc(50% - 15px);
        transform-origin: left bottom;
        transform: rotate(40deg);
        z-index: 2;
      }
      .c-box {
        width: 132px;
        height: 132px;
        background-color: #fff;
        border-radius: 50%;
        font-size: 30px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        &::after {
          position: absolute;
          content: "";
          width: 30px;
          height: 30px;
          background-color: #fff;
          top: 18px;
          transform-origin: right top;
          transform: rotate(60deg);
          left: 50%;
        }
        &::before {
          position: absolute;
          content: "";
          width: 30px;
          height: 30px;
          background-color: #fff;
          bottom: 18px;
          transform-origin: left bottom;
          transform: rotate(60deg);
          right: 50%;
        }
      }
    }
  }
}

@media screen and(max-width:1000px) {
  .px-marketingToolGraphicText3-box {
    .title {
      font-size: 16px !important;
    }
    .sub-title {
      font-size: 12px !important;
    }
    .a-box {
      .cell {
        width: 230px;
        padding: 10px !important;
        .c-icon {
          width: 24px !important;
        }
      }
      .line3 {
        margin-bottom: 0;
      }
    }
    .c-title {
      font-size: 14px !important;
    }
    .c-content {
      font-size: 12px !important;
      white-space: nowrap;
    }
  }
}

@media screen and(max-width:500px) {
  .px-marketingToolGraphicText3-box {
    padding: 25px 0 !important;
    .title {
      font-size: 15px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
    }
    .sub-title {
      font-size: 12px !important;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
    }
    .a-box {
      margin-top: 20px;
      .cell {
        padding: 10px 4px !important;
        .c-icon {
          width: 20px !important;
          margin-top: -6px !important;
        }
        .c-title {
          font-size: 12px !important;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
        }
        .c-content {
          font-size: 10px !important;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          line-height: 14px;
        }
      }
      .line1 {
        margin-bottom: 20px !important;
        .cell {
          &::after {
            border-width: 5px;
            bottom: -10px;
          }
        }
      }
      .line3 {
        margin-top: 20px !important;

        .cell {
          &::after {
            border-width: 5px;
            top: -10px;
          }
        }
      }
      .line1,
      .line3 {
        .cell {
          width: 234px;
        }
      }
      .line2 {
        .cell {
          width: 140px;
          padding: 10px 0px !important;

          &:first-child {
            margin-right: 10px;
            &::after {
              border-width: 5px !important;
              right: -10px !important;
            }
          }
          &:last-child {
            margin-left: 10px;
            &::after {
              border-width: 5px !important;
              left: -10px !important;
            }
          }
        }
        .b-box {
          width: 60px;
          min-width: 60px;
          height: 60px;
          &::after {
            border-width: 6px;
            top: 4px;
          }
          &::before {
            border-width: 6px;
            bottom: 4px;
          }
          .c-box {
            font-size: 9px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;
            width: 44px;
            height: 44px;
            text-align: center;
            &::after {
              width: 10px;
              height: 10px;
              top: 5px;
              left: 47%;
            }
            &::before {
              width: 10px;
              height: 10px;
              bottom: 6px;
              right: 47%;
            }
          }
        }
      }
    }
  }
}
</style>